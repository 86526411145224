import {
    Chart as ChartJS,
    RadialLinearScale,
    ArcElement,
    Tooltip,
    Legend,
} from "chart.js";
import { PolarArea } from "react-chartjs-2";
import { Scores } from "@/features/lifestyle-medicine-assessment/types.ts";

ChartJS.register(RadialLinearScale, ArcElement, Tooltip, Legend);

type ChartProps = {
    scores: Scores;
};

export function Chart({ scores }: ChartProps) {
    const data = {
        labels: [
            "Connectedness",
            "Movement",
            "Nutrition",
            "Recovery",
            "Substance",
        ],
        datasets: [
            {
                label: "Domain Score: ",
                data: [
                    scores.connection.score,
                    scores.movement.score,
                    scores.nutrition.score,
                    scores.recovery.score,
                    scores.substance.score,
                ],
                backgroundColor: [
                    "rgba(126, 34, 206, 0.5)",
                    "rgba(185, 28, 28, 0.5)",
                    "rgba(21, 128, 61, 0.5)",
                    "rgba(29, 78, 216, 0.5)",
                    "rgba(161, 98, 7, 0.5)",
                ],
                borderWidth: 1,
            },
        ],
    };

    return <PolarArea data={data} />;
}
